import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'gatsby';
import { getFormValues, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';

import Form, { defaultSubmitResponseHandler } from '../components/Form';
import wrapInSection from '../components/wrapInSection';
import wrapInLayout from '../components/wrapInLayout';

const fields = [
  {
    name: 'email',
    label: 'Почта',
  },
];

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Обязательное поле';
  }

  return errors;
};

const submit = values =>
  axios.post('/api/auth/forgot_password', {}, { params: values });

const SuccessMessage = ({ values: { email } }) => (
  <div className="confirmation-message">
    <p className="fa-wrapper">
      <i className="fa fa-check" />
    </p>
    <p>
      Готово! Мы отправили ссылку для сброса пароля на <strong>{email}</strong>.
    </p>
  </div>
);

const ForgotPassword = () => (
  <div className="page-wrapper">
    <div className="row">
      <div className="col-md-5">
        <h2>Восстановление пароля</h2>
        <Form
          submitUrl="/auth/forgot_password"
          fields={fields}
          form="forgotPassword"
          validate={validate}
          successMessage={SuccessMessage}
          onSubmit={submit}
        />
      </div>
    </div>
  </div>
);

export default wrapInLayout(wrapInSection(ForgotPassword));
